import {
	IWebSocketService
} from '../../interfaces';
import { WebSocketGatewaySubscriber } from './web-socket-gateway-subscriber';
import { IWebSocketGateway } from './web-socket-gateway.interface';

export class WebSocketService implements IWebSocketService {
	private webSocketGateway: IWebSocketGateway;

	public constructor(webSocketGateway: IWebSocketGateway) {
		this.webSocketGateway = webSocketGateway;
	}

	public subscribeToWebSocketEvent(eventType, subscriber) {
		this.webSocketGateway.addSubscriber(new WebSocketGatewaySubscriber(eventType, subscriber));
	}
}
