import config from 'config';
import fetchWrapper from '../../libs/FetchWrapper';
import trackError from '../../libs/trackError';
import { ServicelayerDriver } from './classes/servicelayer-driver/servicelayer-driver';
import { StudentapiDriver } from './classes/studentapi-driver/studentapi-driver';
import { Student } from './student';


window['Student'] = new Student(
	new StudentapiDriver(config.studentApiBaseUrl, window['AuthController'], fetchWrapper, trackError),
	new ServicelayerDriver(config.serviceLayerBaseUrl, window['AuthController'], fetchWrapper, trackError),
	trackError
);
