import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { ITokenBasedSession } from '..';
import { SessionServiceEventType } from '../enums/event-types';

export class SessionCreatedEvent implements IEvent {

	public readonly timestamp: Date;
	public readonly eventType = SessionCreatedEvent.EventType;
	public readonly session: ITokenBasedSession;

	public static EventType = SessionServiceEventType.SESSION_CREATED;

	constructor(session: ITokenBasedSession) {
		this.timestamp = new Date();
		this.session = session;
	}
}
