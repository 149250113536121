import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { IWishlistService } from '../application/wishlist-service.interface';
import { WishlistServiceEventType } from '../enums/event-type';

export class WishlistSyncedEvent implements IEvent {

	constructor(
	) {
		this.timestamp = new Date();
	}

	public static EventType: string = WishlistServiceEventType.WISHLIST_SYNCED;

	public readonly timestamp: Date;
	public readonly eventType = WishlistSyncedEvent.EventType;
}
