import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { InternalSessionServiceEventType } from '../enums/internal-session-service-event-type';

export class SessionRepositoryReadyEvent implements IEvent {
	public static EventType: string = InternalSessionServiceEventType.SESSION_REPOSITORY_READY;

	public readonly timestamp: Date;
	public readonly eventType: string = SessionRepositoryReadyEvent.EventType;

	constructor() {
		this.timestamp = new Date();
	}
}
