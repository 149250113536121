import { FavouriteAdded } from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';
import { WebSocketFavouriteAddedSubscriber } from './websocket-favourite-added-subscriber';
import { IWebSocketService } from 'interfaces';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

export class WebSocketFavouriteAddedAdapter {

	constructor(
		private readonly webSocketService: IWebSocketService,
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache) {
	}

	public initialize(): void {
		this.webSocketService.subscribeToWebSocketEvent(
			FavouriteAdded.EventType,
			new WebSocketFavouriteAddedSubscriber(
				this.eventAggregationService,
				this.wishlistCache
			)
		);
	}
}
