import {
	IEventEmitter
} from '@studyportals/wishlist-service-core';
import { IEvent, IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

export class EventAggregationServiceEventEmitter implements IEventEmitter {

	constructor(public eventAggregationService: IEventAggregationService) {

	}

	public async emit(eventName: string, event: IEvent): Promise<void> {
		this.eventAggregationService.publishTo(event.eventType, event);
	}

}
