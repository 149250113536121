import { ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class WebSocketGatewaySubscriber<T> {

	private sequenceNo: number;

	constructor(
		public readonly eventType: string | RegExp,
		public readonly handler: ISubscriber<T>
	) {
		this.sequenceNo = 0;
	}

	public canHandle(eventType: string) {

		if (typeof this.eventType === 'string') {

			return this.eventType.toLowerCase() === eventType.toLowerCase();
		} else {

			return this.eventType.test(eventType);
		}
	}

	public handle(event: T) {

		this.handler.notify(event, this.sequenceNo++);
	}
}
