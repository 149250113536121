import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import {
	ITokenBasedSessionService, SessionCreatedEvent,
	SessionDestroyedEvent,
	SessionServiceReadyEvent
} from '../../../../interfaces/session-management';
import { ITokenBasedSession } from '../../../../interfaces/session-management';
import { CallbackSubscriber } from '../../../event-aggregation-service/callback-subscriber';
import { OnceCallbackSubscriber } from '../../../event-aggregation-service/once-callback-subscriber';
import { Deferred } from '../../auth-controller/classes/deferred';
import { CatchReportAsyncException, CatchReportException } from '../../decorators/error-decorator';
import { RefreshTokenError } from '../errors/refresh-token-error';
import { SessionRefreshedEvent } from "../events/session-refreshed-event";
import { SessionRepositoryReadyEvent } from "../events/session-repository-ready-event";
import { ITokenBasedSessionRepository } from '../interfaces/token-based-session-repository.interface';
import { ITokenService } from '../interfaces/token-service.interface';

export class TokenBasedSessionService implements ITokenBasedSessionService {

	public isReady = false;
	private tokenRepository: ITokenBasedSessionRepository;
	private eventAggregationService: IEventAggregationService;
	private tokenService: ITokenService;
	private session: ITokenBasedSession | Promise<ITokenBasedSession> = null;
	private deferred: Deferred;

	constructor(
		tokenRepository: ITokenBasedSessionRepository,
		eventAggregationService: IEventAggregationService,
		tokenService: ITokenService
	) {

		this.tokenRepository = tokenRepository;
		this.eventAggregationService = eventAggregationService;
		this.tokenService = tokenService;
		this.deferred = new Deferred();
	}

	@CatchReportException
	public initialize(): void {

		this.eventAggregationService.subscribeTo(SessionRepositoryReadyEvent.EventType, this.onSessionRepositoryReady, true);
		this.eventAggregationService.subscribeTo(SessionDestroyedEvent.EventType, this.onSessionDestroyed);
		this.eventAggregationService.subscribeTo(SessionRefreshedEvent.EventType, this.onSessionRefreshed);
	}

	private get onSessionRepositoryReady(): CallbackSubscriber<SessionRepositoryReadyEvent> {

		return new CallbackSubscriber<SessionRepositoryReadyEvent>(async () => {
			this.openSessionService();
		});
	}

	private get onSessionRefreshed(): CallbackSubscriber<SessionRefreshedEvent> {

		return new OnceCallbackSubscriber<SessionRefreshedEvent>(async () => {
			await this.saveRefreshedSession();
		});
	}

	private get onSessionDestroyed(): CallbackSubscriber<SessionDestroyedEvent> {

		return new CallbackSubscriber<SessionDestroyedEvent>(
			async () => {

				await this.clearSession();
			}
		);
	}

	@CatchReportAsyncException
	public async getSession(): Promise<ITokenBasedSession | null> {

		try {

			if (this.session === null) {
				await this.loadSession();
			}

			return await this.session;
		} catch (error) {

			return null;
		}
	}

	@CatchReportAsyncException
	public async setSession(session: ITokenBasedSession): Promise<void> {

		try {
			// Wait until token repositories is ready
			await this.deferred.untilOpen();

			this.session = session;
			// Check if the accessToken is valid, that would internally refresh it
			// and we can save the fresh session in the tokenRepository
			await session.getAccessToken();
			await this.tokenRepository.save(session);
			// Everything is fresh and saved, the session created event can be send
			this.eventAggregationService.publishTo(SessionCreatedEvent.EventType, new SessionCreatedEvent(session));
		} catch (error) {

			this.session = null;
			// If there are any errors we need to make sure everyone reacts accordingly
			console.error(error);
			this.eventAggregationService.publishTo(SessionDestroyedEvent.EventType, new SessionDestroyedEvent());
		}

	}

	@CatchReportAsyncException
	public async globalLogout(): Promise<void> {

		try {

			const session = await this.tokenRepository.get();
			const accessToken = await session.getAccessToken();

			await this.tokenService.logoutSubject(accessToken);

		} catch (error) {
			if (!(error instanceof RefreshTokenError)) {
				console.error(error);
			}
		}

		this.eventAggregationService.publishTo(SessionDestroyedEvent.EventType, new SessionDestroyedEvent());
	}

	@CatchReportException
	private openSessionService(): void {
		this.deferred.open();
		this.isReady = true;

		const event = new SessionServiceReadyEvent(this);
		this.eventAggregationService.publishTo(SessionServiceReadyEvent.EventType, event);
	}

	@CatchReportAsyncException
	private async saveRefreshedSession(): Promise<void> {
		await this.tokenRepository.save(await this.session);
	}

	@CatchReportAsyncException
	private async clearSession(): Promise<void> {
		this.session = null;
		await this.tokenRepository.clear();
	}

	private loadSession(): Promise<void> {
		if (this.session !== null) {
			return;
		}

		this.session = this.getSessionIfUsable();
	}

	private async getSessionIfUsable(): Promise<ITokenBasedSession|null> {
		try {
			const session = await this.tokenRepository.get();

			return await session.isUsable() ? session : null;
		} catch (error) {
			return null;
		}
	}

}
