import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { IWishlistService } from '../application/wishlist-service.interface';
import { WishlistServiceEventType } from '../enums/event-type';

export class WishlistServiceReadyEvent implements IEvent {

	constructor(
		public wishlistService: IWishlistService
	) {
		this.timestamp = new Date();
	}

	public static EventType: string = WishlistServiceEventType.WISHLIST_SERVICE_READY;

	public readonly timestamp: Date;
	public readonly eventType = WishlistServiceReadyEvent.EventType;
}
