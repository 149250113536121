import {
	IEventAggregationService,
	ISubscriber
} from '@studyportals/event-aggregation-service-interface';
import {
	ISession,
	SessionCreatedEvent,
	SessionServiceReadyEvent
} from '../../../../interfaces/session-management';

declare const Rollbar;

export class RollbarIdentitySubscriber implements ISubscriber<SessionServiceReadyEvent | SessionCreatedEvent> {

	constructor(
		private eventAggregationService: IEventAggregationService
	) {

	}

	public subscribe(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
		this.eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, this, true);
	}

	public async notify(event: SessionServiceReadyEvent | SessionCreatedEvent): Promise<void> {
		if (event.eventType === SessionCreatedEvent.EventType) {
			return this.initializeRollbarIdentity((event as SessionCreatedEvent).session);
		}

		if (event.eventType === SessionServiceReadyEvent.EventType) {
			await this.handleSessionServiceReadyEvent(event as SessionServiceReadyEvent);
		}

	}

	private async handleSessionServiceReadyEvent(event: SessionServiceReadyEvent): Promise<void> {
		const sessionService = event.sessionService;
		const session = await sessionService.getSession();

		if (session === null) {
			return;
		}

		this.initializeRollbarIdentity(session);
	}

	private initializeRollbarIdentity(session: ISession): void {
		if (typeof Rollbar === 'undefined') {
			return;
		}

		Rollbar.configure({
			payload: {
				person: {
					id: session.getUser().identityId
				}
			}
		} as any);
	}

}
