import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { EventAggregationService } from '@studyportals/event-aggregation-service';

export class GlobalEventAggregationService {
	public static initialize(): IEventAggregationService {
		window.EventAggregationService = new EventAggregationService();
		document.dispatchEvent(new Event('AsyncEventAggregationServiceLoaded'));

		return window.EventAggregationService;
	}
}
