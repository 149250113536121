/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { PortalMap, ProgrammeCardClient } from "@studyportals/domain-client";
import { Favourite, FavouriteProperties, ISerializedWishlist, Study } from '@studyportals/wishlist-service-core';
import { SerializableWishlist } from "../entities/serializable-wishlist";


export class WishlistFactory {

	private programmeCardClient: ProgrammeCardClient;

	constructor() {
		this.programmeCardClient = new ProgrammeCardClient(
			'url',
			'key',
			this.getRandomInt(1, 100),
			this.getPortalMap()
		)
	}

	public translateSerializedWishlist(serializedWishlist: ISerializedWishlist): SerializableWishlist {
		const wishlist = new SerializableWishlist(serializedWishlist.userId, serializedWishlist);

		serializedWishlist.favourites.forEach((serializedFavourite) => {
			const study = this.createStudy(serializedFavourite);
			const dateAdded = new Date(serializedFavourite.dateAdded);

			const translatedProperties = this.translateProperties(serializedFavourite.properties);
			const favourite = new Favourite(study, dateAdded, translatedProperties);

			wishlist.addFavourite(favourite);
		});

		return wishlist;
	}

	private translateProperties (serializedFavouriteProperties: FavouriteProperties): FavouriteProperties {
		const translatedProperties = serializedFavouriteProperties;

		if (translatedProperties?.application !== undefined) {
			if (translatedProperties.application?.startDate !== undefined) {
				translatedProperties.application.startDate = new Date(translatedProperties.application.startDate);
			}
			if (translatedProperties.application?.deadlineDate !== undefined) {
				translatedProperties.application.deadlineDate = new Date(translatedProperties.application.deadlineDate);
			}
		}

		return translatedProperties;
	}

	public createStudy(serializedFavourite: any): Study {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const studyInfo = serializedFavourite.study;
		let card = undefined;

		if (studyInfo.card !== undefined) {
			card = this.programmeCardClient.fromDescription(studyInfo.card);
		}

		return new Study(studyInfo.id, card);
	}

	private getPortalMap(): undefined | PortalMap {
		if (!window['PortalMap']) {
			return undefined;
		}

		return new Map(Object.entries(window['PortalMap'] as PortalMap)) as PortalMap;
	}

	private getRandomInt(min: number, max: number): number {
		min = Math.ceil(min);
		max = Math.floor(max);

		// The maximum is exclusive and the minimum is inclusive
		return Math.floor(Math.random() * (max - min) + min);
	}
}
