import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import {
	FavouriteApplicationStatusChanged,
	FavouriteAssessmentsChanged,
	FavouriteRemoved
} from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebsocketFavouriteAssessmentsChangedSubscriber implements ISubscriber<FavouriteAssessmentsChanged> {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache
	) { }

	async notify(event: FavouriteAssessmentsChanged): Promise<void> {

		await this.updateFavourite(event);
		const isLocalEvent = this.wishlistCache.isStateHashLocal(event.stateHash);

		this.eventAggregationService.publishTo(
			FavouriteAssessmentsChanged.EventType,
			new FavouriteAssessmentsChanged(
				event.userId,
				event.studyId,
				event.assessments,
				event.eventId,
				event.stateHash,
				new Date(event.timestamp),
				isLocalEvent
			)
		);
	}

	private async updateFavourite(event: FavouriteAssessmentsChanged): Promise<void> {

		const wishlist = await this.wishlistCache.getWishlist();
		if (wishlist) {
			const favourite = wishlist.findFavourite(event.studyId);
			if(!favourite){
				return;
			}

			favourite.setAssessments(event.assessments);
			await this.wishlistCache.setWishlist(wishlist);
		}
	}

}
