import config from 'config';
import { Bootstrapper } from './environment/bootstrapper';

const sptrackerConfig: any = {
	environment: (config.stage) ? config.stage : 'dev',
	team: 'xm',
	product: 'WishlistService',
	category: 'Wishlist',
};

function bootstrapWishlistService() {
	const bootstrapper = new Bootstrapper(
		window['EventAggregationService'],
		config.wishlistServiceBaseUrl,
		sptrackerConfig,
	);

	bootstrapper.configureEnvironment();
	bootstrapper.setupApplication();
}

if(window['EventAggregationService']){
	bootstrapWishlistService()
} else {
	document.addEventListener('AsyncEventAggregationServiceLoaded', () => bootstrapWishlistService());
}
