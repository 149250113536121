import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import {
	FavouriteApplication,
	FavouriteApplicationChanged
} from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebsocketFavouriteApplicationChangedSubscriber implements ISubscriber<FavouriteApplicationChanged> {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache
	) { }

	async notify(event: FavouriteApplicationChanged): Promise<void> {

		const translatedApplication = this.translateApplication(event.application);
		await this.updateFavourite(translatedApplication, event.studyId);
		const isLocalEvent = this.wishlistCache.isStateHashLocal(event.stateHash);

		this.eventAggregationService.publishTo(
			FavouriteApplicationChanged.EventType,
			new FavouriteApplicationChanged(
				event.userId,
				event.studyId,
				translatedApplication,
				event.eventId,
				event.stateHash,
				new Date(event.timestamp),
				isLocalEvent
			)
		);
	}


	private translateApplication(application: FavouriteApplication | null): FavouriteApplication | null {
		if(application === null){
			return null;
		}

		if(application.startDate){
			application.startDate = new Date(application.startDate);
		}

		if(application.deadlineDate){
			application.deadlineDate = new Date(application.deadlineDate);
		}

		return application;
	}

	private async updateFavourite(application: FavouriteApplication | null, studyId: number): Promise<void> {

		const wishlist = await this.wishlistCache.getWishlist();
		if (wishlist) {
			const favourite = wishlist.findFavourite(studyId);
			if(!favourite){
				return;
			}

			favourite.setApplication(application);
			await this.wishlistCache.setWishlist(wishlist);
		}
	}
}
