import {
	FavouriteApplication,
	FavouriteApplicationStatus, FavouriteAssessments,
	IncorrectWishlistState,
	ISerializedWishlist,
	Wishlist,
	WishlistException,
	WishlistNotFound,
} from '@studyportals/wishlist-service-core';
import {ISessionService, ITokenBasedSession} from '../../../interfaces/session-management';
import {IWishlistService} from '../../../interfaces/wishlist-service';
import FetchWrapper from '../../../libs/FetchWrapper';
import Actions from '../../../libs/Tracking/Actions';
import Labels from '../../../libs/Tracking/Labels';
import SPTracker from '../../../libs/Tracking/SPTracker';
import Tags from '../../../libs/Tracking/Tags';
import {TokenBasedSession} from '../../session-management/domain/entities/token-based-session';
import {SerializableWishlist} from '../domain/entities/serializable-wishlist';
import {WishlistFactory} from '../domain/factories/wishlist-factory';
import {WishlistCache} from '../infrastructure/wishlist-cache';

export class DecisionMakingApiWishlistService implements IWishlistService {
	private wishlistFactory = new WishlistFactory();

	private get fetch() {
		return FetchWrapper;
	}

	constructor(
		private sessionService: ISessionService,
		private baseUrl: string,
		private wishlistCache: WishlistCache,
		private tracker: SPTracker,
	) {
	}

	public async addFavourite(studyId: number): Promise<void> {
		const wishlist = await this.getWishlist();

		const response = await this.executeRequest('', 'POST', {
			studyId,
			expectedState: wishlist.stateHash,
		});

		if (response.resultingState) {
			this.wishlistCache.addLocalStateHash(response.resultingState);
		}
	}

	public async getWishlist(): Promise<Wishlist> {
		let wishlist = await this.wishlistCache.getWishlist();

		if (wishlist === null) {
			wishlist = await this.retrieveWishlistFromApi();
			await this.wishlistCache.setWishlist(wishlist);
		}

		return wishlist;
	}

	public async removeFavourite(studyId: number): Promise<void> {
		const wishlist = await this.getWishlist();

		const response = await this.executeRequest('', 'DELETE', {
			studyId,
			expectedState: wishlist.stateHash,
		});

		if (response.resultingState) {
			this.wishlistCache.addLocalStateHash(response.resultingState);
		}
	}

	public async setOrder(targetStudyId: number, position: number): Promise<void> {
		const wishlist = await this.getWishlist();

		const response = await this.executeRequest('/order', 'PATCH', {
			targetStudyId,
			position,
			expectedState: wishlist.stateHash,
		});

		if (response.resultingState) {
			this.wishlistCache.addLocalStateHash(response.resultingState);
		}
	}

	public async setFavouriteApplicationStatus(studyId: number, applicationStatus: FavouriteApplicationStatus): Promise<void> {
		const wishlist = await this.getWishlist();

		const response = await this.executeRequest('/favourite/application-status', 'PUT', {
			studyId,
			applicationStatus,
			expectedState: wishlist.stateHash,
		});

		if (response.resultingState) {
			this.wishlistCache.addLocalStateHash(response.resultingState);
		}
	}

	public async setFavouriteApplication(studyId: number, application: FavouriteApplication | null): Promise<void> {
		const wishlist = await this.getWishlist();

		const response = await this.executeRequest('/favourite/application', 'PUT', {
			studyId,
			application,
			expectedState: wishlist.stateHash,
		});

		if (response.resultingState) {
			this.wishlistCache.addLocalStateHash(response.resultingState);
		}
	}

	public async setFavouriteAssessments(studyId: number, assessments: FavouriteAssessments): Promise<void> {
		const wishlist = await this.getWishlist();

		const response = await this.executeRequest('/favourite/assessments', 'PUT', {
			studyId,
			assessments,
			expectedState: wishlist.stateHash,
		});

		if (response.resultingState) {
			this.wishlistCache.addLocalStateHash(response.resultingState);
		}
	}

	private async retrieveWishlistFromApi(): Promise<SerializableWishlist> {
		const serializedWishlist: ISerializedWishlist = (await this.executeRequest(
			'',
		)).wishlist;
		return this.wishlistFactory.translateSerializedWishlist(serializedWishlist);
	}

	private async getRequestOptions(method: string, data: any): Promise<any> {
		const options = {
			headers: {
				Authorization: await this.getAuthorizationToken(),
			},
			method,
		};

		if (data !== null) {
			options['body'] = JSON.stringify(data);
		}

		return options;
	}

	private async executeRequest(path: string, method: string = 'GET', data: any = null): Promise<any> {
		try {
			const requestOptions = await this.getRequestOptions(method, data);
			const response = await this.fetch(this.getFullUrl(path), requestOptions);
			const responseBody = await response.json();

			if (!response.ok || responseBody.failed) {
				throw await this.handleError(responseBody);
			}

			return responseBody;
		} catch (error) {
			if (!(error instanceof WishlistException)) {
				error = new Error(
					'An unknown issue encountered while contacting our wishlist service',
				);
			}

			throw error;
		}
	}

	private getFullUrl(path: string): string {
		return `${this.baseUrl}${path}`;
	}

	private async getAuthorizationToken(): Promise<string> {
		const session = (await this.sessionService.getSession()) as TokenBasedSession;
		return session.getAccessToken();
	}

	private async handleIncorrectWishlistStateException(): Promise<IncorrectWishlistState> {
		await this.wishlistCache.clearCache();
		return new IncorrectWishlistState();
	}

	private async handleWishlistNotFoundException(error: any): Promise<WishlistNotFound> {
		await this.wishlistCache.clearCache();
		return new WishlistNotFound(error['wishlistId']);
	}

	private async handleDefaultException(error: any): Promise<WishlistException> {
		if (
			typeof error.message !== 'undefined' &&
			error.message === 'Unauthorized'
		) {
			const session: ITokenBasedSession = await window[
				'SessionService'
				].getSession();
			const user = session.getUser();

			this.tracker.track({
				action: Actions.General,
				label: Labels.JWTAuthError,
				tags: [Tags.Error],
				eventData: JSON.stringify({
					identityId: user.identityId,
					token: await session.getAccessToken(),
					time: Date.now(),
					localTime: new Date().toLocaleString(),
					needsToRefresh: session['shouldRefresh'](),
				}),
			});
		}

		return new WishlistException(
			'An unknown issue encountered while contacting our wishlist service',
		);
	}

	private async handleError(error: any): Promise<WishlistException> {
		switch (error.name) {
			case 'IncorrectWishlistState':
				return this.handleIncorrectWishlistStateException();
			case 'WishlistNotFound':
				return this.handleWishlistNotFoundException(error);
			default:
				return this.handleDefaultException(error);
		}
	}
}
