import {FavouriteApplicationStatusChanged, WishlistOrderChanged} from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';
import { WebsocketWishlistOrderChangedSubscriber } from './websocket-wishlist-order-changed-subscriber';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { IWebSocketService } from '../../../interfaces';
import {
	WebsocketFavouriteApplicationStatusChangedSubscriber
} from "./websocket-favourite-application-status-changed-subscriber";

export class WebsocketFavouriteApplicationStatusChangedAdapter {

	constructor(
		private readonly webSocketService: IWebSocketService,
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache) {
	}

	public initialize(): void {
		this.webSocketService.subscribeToWebSocketEvent(
			FavouriteApplicationStatusChanged.EventType,
			new WebsocketFavouriteApplicationStatusChangedSubscriber(
				this.eventAggregationService,
				this.wishlistCache
			)
		);
	}
}
