import config from 'config';

export function CatchReportAsyncException(
	target: object,
	propertyName: string,
	propertyDescriptor: PropertyDescriptor,
): PropertyDescriptor {

	const originalMethod = propertyDescriptor.value;
	propertyDescriptor.value = async function(...args: any[]): Promise<any> {
		try {
			// @ts-ignore
			return await originalMethod.apply(this, args);
		} catch (error) {
			if (rollbar) {
				rollbar.error(error, args);
			}
			throw error;
		}
	};

	return propertyDescriptor;
}

export function CatchReportException(
	target: object,
	propertyName: string,
	propertyDescriptor: PropertyDescriptor,
): PropertyDescriptor {

	const originalMethod = propertyDescriptor.value;
	propertyDescriptor.value = function(...args: any[]): any {
		try {
			// @ts-ignore
			return originalMethod.apply(this, args);
		} catch (error) {
			if (rollbar) {
				rollbar.error(error, args);
			}
			throw error;
		}
	};

	return propertyDescriptor;
}

const rollbarConfig  = {
	accessToken: '8e11db7363f1449baf8c01438e83c1ca',
	captureUncaught: false,
	captureUnhandledRejections: false,
	checkIgnore: global["rollbarIgnore"],
	payload: {
		environment: config.stage,
	},
};

const rollbar: any = (global && global['rollbar'] && global["rollbarIgnore"]) ? new global['rollbar'](rollbarConfig) : null;
