export default {
    stage: 'prd',
    studentApiBaseUrl: 'https://studentapi.prtl.co',
    hubUrl: 'https://studentsystem.prtl.co/studentjs/dist/storageHub.html?v2',
    serviceLayerBaseUrl: 'https://reflectorjwt.prtl.co',
    wishlistServiceBaseUrl: 'https://decisionmakingapi.prtl.co',
    tokenServiceBaseUrl: 'https://tokens.prtl.co',
    wssEndpoint: 'wss://studentwebsocketservice.prtl.co/v1',
    allowedOrigins: [
	    /((www)|(cypress\.rc)|([0-9]{4,16}(-bob)?\.rc))\.mastersportal.((com)|(fyi))/,
	    /((www)|(cypress\.rc)|([0-9]{4,16}(-bob)?\.rc))\.bachelorsportal\.((com)|(fyi))/,
	    /((www)|(cypress\.rc)|([0-9]{4,16}(-bob)?\.rc))\.phdportal\.((com)|(fyi))/,
	    /((www)|(cypress\.rc)|([0-9]{4,16}(-bob)?\.rc))\.preparationcoursesportal\.((com)|(fyi))/,
	    /((www)|(cypress\.rc)|([0-9]{4,16}(-bob)?\.rc))\.distancelearningportal\.((com)|(fyi))/,
	    /((www)|(cypress\.rc)|([0-9]{4,16}(-bob)?\.rc))\.shortcoursesportal\.((com)|(fyi))/,
	    /personalitytest\.studyportals\.com/,
	    /countrytest\.studyportals\.com/,
	    /scholarship\.mastersportal\.com/
    ]
};
