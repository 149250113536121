import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { ITokenBasedSessionFactory } from '../interfaces/factory.interface';
import { ITokenBasedSessionRepository } from '../interfaces/token-based-session-repository.interface';
import { TokenBasedSessionRepository } from '../repositories/token-based-session-repository';

export class TokenBasedSessionRepositoryFactory {
	constructor(
		private eventAggregationService: IEventAggregationService,
		private tokenSessionFactory: ITokenBasedSessionFactory
	) {

	}

	public create(): ITokenBasedSessionRepository {
		return new TokenBasedSessionRepository(this.eventAggregationService, this.tokenSessionFactory);

	}
}
