import {
	DefaultAddToWishlistCommandHandler,
	DefaultGetWishlistQueryHandler,
	DefaultRemoveFromWishlistCommandHandler,
	SetWishlistOrderCommandHandler
} from '@studyportals/wishlist-service-core';

import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import {
	ISessionService,
	IWishlistService,
	SessionCreatedEvent,

	SessionDestroyedEvent,
	SessionServiceReadyEvent,
	WebsocketServiceReadyEvent,
	WishlistServiceReadyEvent
} from '../../../interfaces';
import { WishlistSyncedEvent } from '../../../interfaces/wishlist-service/events/wishlist-synced-event';
import SPTracker from '../../../libs/Tracking/SPTracker';
import { DataStorageDriver } from '../../common/data-storage-driver';
import { CallbackSubscriber } from '../../event-aggregation-service/callback-subscriber';
import { DecisionMakingApiWishlistService } from '../application/decision-making-api-wishlist-service';
import { LocalWishlistService } from '../application/local-wishlist-service';
import { QueueWishlistService } from '../application/queue-wishlist-service';
import { RetryCommandWishlistService } from '../application/retry-command-wishlist-service';
import { WebSocketFavouriteAddedAdapter } from '../domain/websocket-favourite-added-adapter';
import { WebSocketFavouriteRemovedAdapter } from '../domain/websocket-favourite-removed-adapter';
import { WebsocketWishlistOrderChangedAdapter } from '../domain/websocket-wishlist-order-changed-adapter';
import { EventAggregationServiceEventEmitter } from '../infrastructure/event-aggregation-event-emitter';
import { LocalWishlistRepository } from '../infrastructure/local-wishlist-repository';
import { WishlistCache } from '../infrastructure/wishlist-cache';
import {
	WebsocketFavouriteApplicationStatusChangedAdapter
} from "../domain/websocket-favourite-application-status-changed-adapter";
import {WebsocketFavouriteAssessmentsChangedAdapter} from "../domain/websocket-favourite-assessments-changed-adapter";
import { WebsocketFavouriteApplicationChangedAdapter } from "../domain/websocket-favourite-application-changed-adapter";

export class Bootstrapper {

	private localWishlistService: IWishlistService = null;
	private remoteWishlistService: IWishlistService = null;
	private localWishlistRepository: LocalWishlistRepository = null;
	private queueWishlistService: QueueWishlistService = new QueueWishlistService();
	private wishlistCache: WishlistCache = null;
	private sessionService: ISessionService = null;

	constructor(
		private eventService: IEventAggregationService | undefined,
		private wishlistBaseUrl: string,
		private spTrackerConfig: any) {
	}

	public configureEnvironment(): void {
		const dataStorageDriver = new DataStorageDriver();
		this.wishlistCache = new WishlistCache(
			dataStorageDriver
		);

		this.localWishlistService = this.createLocalWishlistService(dataStorageDriver);
	}

	public setupApplication(): void {
		if (this.eventService === undefined) {
			return;
		}

		this.setupOnSessionServiceReady();
		this.eventService.subscribeTo(SessionCreatedEvent.EventType, new CallbackSubscriber(() => this.onSessionCreated()));
		this.eventService.subscribeTo(SessionDestroyedEvent.EventType, new CallbackSubscriber(() => this.onSessionDestroyed()));
		this.eventService.subscribeTo(WebsocketServiceReadyEvent.EventType,
			new CallbackSubscriber<WebsocketServiceReadyEvent>((e) => this.onWishlistServiceReady(e)), true);
	}

	private setWishlistService(wishlistService: IWishlistService): void {
		this.queueWishlistService.setWishlistService(wishlistService);
	}

	private async synchroniseLocalWishlistToRemote(): Promise<void> {
		const localWishlist = await this.localWishlistService.getWishlist();
		if (localWishlist !== null) {

			await this.remoteWishlistService.getWishlist();

			for (let favouriteIndex in localWishlist.favourites) {
				const favourite = localWishlist.favourites[favouriteIndex];
				const studyId = favourite.study.id;
				await this.remoteWishlistService.addFavourite(studyId);
			}

			await this.localWishlistRepository.removeWishlist();
		}

		await this.eventService.publishTo(WishlistSyncedEvent.EventType, new WishlistSyncedEvent());
	}

	private async onSessionDestroyed(): Promise<void> {
		this.setWishlistService(this.localWishlistService);
		await this.wishlistCache.clearCache();
	}

	private async onSessionCreated(): Promise<void> {
		this.setWishlistService(this.remoteWishlistService);
		await this.synchroniseLocalWishlistToRemote();
	}

	private async onWishlistServiceReady(event: WebsocketServiceReadyEvent): Promise<void> {
		new WebSocketFavouriteAddedAdapter(event.webSocketService, this.eventService, this.wishlistCache).initialize();
		new WebSocketFavouriteRemovedAdapter(event.webSocketService, this.eventService, this.wishlistCache).initialize();
		new WebsocketWishlistOrderChangedAdapter(event.webSocketService, this.eventService, this.wishlistCache).initialize();
		new WebsocketFavouriteApplicationStatusChangedAdapter(event.webSocketService, this.eventService, this.wishlistCache).initialize();
		new WebsocketFavouriteApplicationChangedAdapter(event.webSocketService, this.eventService, this.wishlistCache).initialize();
		new WebsocketFavouriteAssessmentsChangedAdapter(event.webSocketService, this.eventService, this.wishlistCache).initialize();
	}

	private setupOnSessionServiceReady(): void {
		this.eventService.subscribeTo(SessionServiceReadyEvent.EventType,
			new CallbackSubscriber((e) => this.onSessionServiceReady(e as SessionServiceReadyEvent)), true);
	}

	private async onSessionServiceReady(e: SessionServiceReadyEvent): Promise<void> {
		this.sessionService = e.sessionService;
		this.remoteWishlistService = this.createRemoteWishlistService();

		const session = await this.sessionService.getSession();

		if (session === null) {
			this.setWishlistService(this.localWishlistService);
		} else {
			this.setWishlistService(this.remoteWishlistService);
			this.synchroniseLocalWishlistToRemote();
		}

		const event = new WishlistServiceReadyEvent(this.queueWishlistService);
		this.eventService.publishTo(WishlistServiceReadyEvent.EventType, event);
	}

	private createLocalWishlistService(dataStorageDriver: DataStorageDriver): IWishlistService {
		const eventEmitter = new EventAggregationServiceEventEmitter(this.eventService);
		const localWishlistRepository = new LocalWishlistRepository(dataStorageDriver);
		this.localWishlistRepository = localWishlistRepository;

		const addToWishlistCommandHandler = new DefaultAddToWishlistCommandHandler(localWishlistRepository, eventEmitter);
		const removeFromWishlistCommandHandler = new DefaultRemoveFromWishlistCommandHandler(localWishlistRepository, eventEmitter);
		const setWishlistOrderCommandHandlerCommandHandler = new SetWishlistOrderCommandHandler(localWishlistRepository, eventEmitter);
		const getWishlistCommandHandler = new DefaultGetWishlistQueryHandler(localWishlistRepository);

		return new RetryCommandWishlistService(
			new LocalWishlistService(
				addToWishlistCommandHandler,
				getWishlistCommandHandler,
				removeFromWishlistCommandHandler,
				setWishlistOrderCommandHandlerCommandHandler
			)
		);
	}

	private createRemoteWishlistService(): IWishlistService {
		if (this.sessionService === null) {
			return;
		}

		const tracker = new SPTracker(this.spTrackerConfig);

		return new RetryCommandWishlistService(
			new DecisionMakingApiWishlistService(
				this.sessionService,
				this.wishlistBaseUrl,
				this.wishlistCache,
				tracker
			)
		);
	}
}
