import { FavouriteRemoved } from '@studyportals/wishlist-service-core';

import { WishlistCache } from '../infrastructure/wishlist-cache';
import { WebSocketFavouriteRemovedSubscriber } from './websocket-favourite-removed-subscriber';
import { IWebSocketService } from 'interfaces';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

export class WebSocketFavouriteRemovedAdapter {

	constructor(
		private readonly webSocketService: IWebSocketService,
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache) {
	}

	public initialize(): void {
		this.webSocketService.subscribeToWebSocketEvent(
			FavouriteRemoved.EventType,
			new WebSocketFavouriteRemovedSubscriber(
				this.eventAggregationService,
				this.wishlistCache
			)
		);
	}
}
