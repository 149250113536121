import { Wishlist } from '@studyportals/wishlist-service-core';
import { ILocalStorageDriver } from '../../common/local-storage-driver.interface';
import { SerializableWishlist } from "../domain/entities/serializable-wishlist";
import { WishlistFactory } from '../domain/factories/wishlist-factory';

export class WishlistCache {

	private wishlist: SerializableWishlist = null;
	private storageKey = 'remote-wishlist-cache';
	private cacheTTL = 3600; // 1 hour
	private wishlistFactory = new WishlistFactory();
	private localStateHashes: string[];

	constructor(private localStorageDriver: ILocalStorageDriver) {
		this.localStateHashes = [];
	}

	public async setWishlist(wishlist: SerializableWishlist): Promise<void> {
		this.wishlist = wishlist;
		await this.localStorageDriver.set(this.storageKey, JSON.stringify(this.wishlist.serialize()), this.cacheTTL);
	}

	public async getWishlist(): Promise<SerializableWishlist> {

		if (this.wishlist === null) {
			const serializedWishlist = await this.localStorageDriver.get(this.storageKey);

			if (serializedWishlist === '' || serializedWishlist === null) {
				return null;
			}

			this.wishlist = this.wishlistFactory.translateSerializedWishlist(JSON.parse(serializedWishlist));
		}

		return this.wishlist;
	}

	public async clearCache(): Promise<void> {
		this.wishlist = null;
		await this.localStorageDriver.remove(this.storageKey);
	}

	public addLocalStateHash(stateHash: string): void {
		this.localStateHashes.push(stateHash);
	}

	public isStateHashLocal(stateHash: string): boolean {
		return this.localStateHashes.includes(stateHash);
	}

}
